/*

    =========================
    DEPENDANCES NODEJS
    =========================

 */

global.$ = global.jQuery = require('jquery');
global.FontFaceObserver = require('fontfaceobserver/fontfaceobserver');
require('slick-carousel');

// Modules persos
var Form = require('./modules/Form');
var Sliders = require('./modules/Sliders');
var GrilleFlexible = require('./modules/GrilleFlexible');



/*


    ======================
    GRILLE FLEXIBLE
    ======================
    Pour la gestion des marges en responsive

*/
var configGrilleFlexible = {
    nbColonnes: 24,
    large: {
        classeLarge: 'lg',
        vpLarge: 1096
    },
    medium: {
        classeMedium: 'md',
        vpMedium: 800
    },
    small: {
        classeSmall: 'sm',
        vpSmall: 601
    },
    xsmall: {
        classeXSmall: 'xs',
        vpXSmall: 480
    },
    rows: $('.row > .col')
};

// On attend que le DOM soit chargé pour lancé le script
$(document).ready(function(){
    grilleFlexible = new GrilleFlexible(configGrilleFlexible);
    grilleFlexible.initGrille();
});





/*

    ==============
    SLIDERS
    ==============

*/
var configSliders = {
    sliders: '[id*="id-slider-"]'
};
var sliders = new Sliders(configSliders);
sliders.init();





/*

    =================
    FORMULAIRE
    =================

 */
var form = new Form();
form.init();
