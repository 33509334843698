
//*****************************************//
///////////////// LAYOUT ////////////////////
//*****************************************//

/*
 *  Lorsque l'on applique une gouttière à nos éléments grâce à la grille flexible,
 *  il faut veiller à ce que les marges appliquées soient retirées lorsque le responsive s'active.
 *
 *  Imaginons des elements ayant chacun une goutière appliquée et des classes :
 *      col-lg-6 col-md-12 col-sm-24
 *      col-lg-6 col-md-12 col-sm-24
 *      col-lg-6 col-md-12 col-sm-24
 *      col-lg-6 col-md-12 col-sm-24
 *
 *  Lorsque l'on se retrouve en md, les 4 elements ne se retrouvent plus alignés par 4 mais par 2.
 *  Il faudra donc retirer la gouttière du 3ème élément.
 *
 *  Pour éviter de complexifier la dynamisation du HTML, cette classe se charge de supprimer
 *  les gouttières des éléments qui vont poser problème pour un viewport donné
 *
 *
 *  ATTENTION !! Pour que cela fonctionne correctement, chaque col doit
 *  avoir les 4 tailles de viewport spécifiée en classes => .lg-, .md-, .sm- et .xs-
 */


module.exports = GrilleFlexible = function (config) {
    // Nombre de colonnes de la grille flexible
    this.nbColonnes = config.nbColonnes || 24;

    // Nom des classes pour chaque de viewport que l'on souhaite surveiller
    this.classeLarge = config.large.classeLarge || 'lg';
    this.classeMedium = config.medium.classeMedium || 'md';
    this.classeSmall = config.small.classeSmall || 'sm';
    this.classeXSmall = config.xsmall.classeXSmall || 'xs';

    // Taille des viewports
    this.vpLarge = config.large.vpLarge || 1096;
    this.vpMedium = config.medium.vpMedium || 800;
    this.vpSmall = config.small.vpSmall || 601;
    this.vpXSmall = config.xsmall.vpXSmall || 480;

    // Eléments jquery à observer
    this.rows = config.rows || $('.row > .col');
};



/**
 * Retourne un tableau de valeur représentant les tailles de col pour un viewport donné
 */
GrilleFlexible.prototype.getAllClassesViewport = function(col,viewport){
    // Variable de stockage pour nos éléments
    var largeurCol = [];
    var colLength = col.length;

    for (var i = 0; i < colLength; i++) {
        // Récupération du contenu de l'attribut class="" de l'élément courant ...
        var classes = $(col[i]).attr('class');

        // ... pour ensuite traiter cette chaine de caractère avec une expression régulière
        // Ici, on veut trouver ce type de classe : lg-6, md-8; sm-12, ... lg,md,sm donné
        // en paramètre de la fonction (viewport).
        // Par exemple, si le viewport est lg, on va récupérer le chiffre se trouvant juste après lg-...,  md-...
        var regexLargeurCol = new RegExp('(?![-])' + viewport + '-([0-9]{1,2})(?![-])', 'gi');

        // ... Chaque chiffre extrait de lg-..., md-... est stocké dans un tableau.
        // En résumé, le tableau est une représentation des largeurs pour chaque .col--margin
        largeurCol.push(parseInt(regexLargeurCol.exec(classes)[1]));
    }

    // On récupère dans un tableau l'ensemble des chiffres des colonnes d'un row donné :
    // |col lg-6 md-12 sm-24
    // |col lg-6 md-12 sm-24
    // |col lg-12 md-12 sm-24
    // |------------------------>> donnera comme résultat (pour un viewport lg : [6,6,12]
    return largeurCol;
};



/**
 *  On parcourt les cols de chaque row. On fait un traitement différents suivant que la col possédant une
 *  classe col--margin ou non
 *
 *  Si la col posséde une classe col--margin :
 *  Ajoute une classe du type [viewport]-[0-9]{1,2}--no-margin aux éléments qui vont poser problème
 *  pour une taille de viewport donné
 *
 *  Si la col ne possède pas de classe col--margin
 *  Ajoute une classe .clearfix à la col qui va poser problème pour un viewport donné
 *
 *  @param rows : jQuery Object => représent tous les groupes de .row contenant des .col--margin
 *  @param viewport : type String
 */
GrilleFlexible.prototype.addNoMargin = function (rows, viewport) {

    var that = this;

    rows.parent().each(function () {

        // Récupération de tous les éléments ayant une marge (possédant un modifieur col--margin)
        var colMargin = $(this).children('.col--margin');
        var colMarginLength = colMargin.length;

        if( colMarginLength ){

            // On récupère dans un tableau l'ensemble des chiffres des colonnes d'un row donné :
            // |col lg-6 md-12 sm-24
            // |col lg-6 md-12 sm-24
            // |col lg-12 md-12 sm-24
            // |------------------------>> donnera comme résultat (pour un viewport lg : [6,6,12]
            var largeurCol = that.getAllClassesViewport(colMargin,viewport);

            var totalLargeurCol = 0; // Cumul des chiffres des col-[viewport]-[0-9]
            var lgsLength = largeurCol.length;
            for (var j = 0; j < lgsLength; j++) {

                totalLargeurCol += largeurCol[j];

                // Si le cumul des col-[viewport]-[0-9] est supérieur au nombre total de colonne implémenté.
                // Cela veut dire que l'élément qu'on est en train de lire ne passera pas sur la même ligne
                // que les éléments déjà parcourus. On va donc lui ajouter une classe qui va lui retirer sa marge.
                if (totalLargeurCol > that.nbColonnes) {

                    // ... on ajoute une classe pour supprimer la gouttière sur l'élément qui posera
                    // problème une fois le responsive activé pour le viewport spécifié
                    var classeNoMarginLg = viewport + '-' + largeurCol[j] + '--no-margin';
                    $(colMargin[j]).addClass(classeNoMarginLg);

                    // Remise à zéro du compteur des largeur des cols
                    totalLargeurCol = largeurCol[j];
                }
            }
        }



        // Récupération des élèments ne possédant pas de marge
        var colNormal = $(this).children('.col:not(.col--margin)');
        var colNormalLength = colNormal.length;

        if( colNormalLength ){

            // On récupère dans un tableau l'ensemble des chiffres des colonnes d'un row donné :
            // |col lg-6 md-12 sm-24
            // |col lg-6 md-12 sm-24
            // |col lg-12 md-12 sm-24
            // |------------------------>> donnera comme résultat (pour un viewport lg : [6,6,12]
            //
            var largeurColNormal = that.getAllClassesViewport(colNormal,viewport);

            var totalLargeurColNormal = 0; // Cumul des chiffres des col-[viewport]-[0-9]
            var lgsLengthNormal = largeurColNormal.length;
            for (var l = 0; l < lgsLengthNormal; l++) {

                totalLargeurColNormal += largeurColNormal[l];

                // Si le cumul des col-[viewport]-[0-9] est supérieur au nombre total de colonne implémenté.
                // Cela veut dire que l'élément qu'on est en train de lire ne passera pas sur la même ligne
                // que les éléments déjà parcourus. On va donc lui ajouter une classe qui va lui retirer sa marge.
                if (totalLargeurColNormal > that.nbColonnes) {

                    // ... on ajoute une classe pour supprimer la gouttière sur l'élément qui posera
                    // problème une fois le responsive activé pour le viewport spécifié
                    $(colNormal[l]).addClass('js-clearfix');

                    // Remise à zéro du compteur des largeur des cols
                    totalLargeurColNormal = largeurColNormal[l];
                }
            }
        }
    });
};



/**
 *  Supprime les classes ..--no-margin pour la taille de viewport spécifié
 *  Supprime la classe .clearfix (quoi qu'il en soit pour le viewport)
 *
 *  @param rows : jQuery Object => représent tous les groupes de .row contenant des .col--margin
 *  @param viewport : type String
 */
GrilleFlexible.prototype.removeNoMargin = function (rows, viewport) {

    rows.parent().each(function () {

        // Récupération de tous les éléments possédant une marge
        var colMargin = $(this).children('.col--margin');
        var colMarginLength = colMargin.length;

        if( colMarginLength ){
            // On parcourt tous les éléments
            for (var i = 0; i < colMarginLength; i++) {
                // Récupération du contenu de l'attribut class="" de l'élément courant ...
                var classes = $(colMargin[i]).attr('class');

                // ... pour ensuite récupérer la largeur de la col que l'on parcourt ...
                var regexLargeurCol = new RegExp('(?![-])' + viewport + '-([0-9]{1,2})(?![-])', 'gi');

                // ... afin de reconstruire la classe à supprimer
                var classDel = viewport + '-' + regexLargeurCol.exec(classes)[1] + '--no-margin';
                $(colMargin[i]).removeClass(classDel + '');
            }
        }


        // Récupération de tous les éléments ne possédant pas de marge
        var colNormal = $(this).children('.col:not(.col--margin)');
        var colNormalLength = colNormal.length;

        if( colNormalLength ){
            // On parcourt tous les éléments
            for (var j = 0; j < colNormalLength; j++) {
                $(colNormal[j]).removeClass('js-clearfix');
            }
        }
    });
};



/**
 * Initialisation des calculs
 */
GrilleFlexible.prototype.initGrille = function () {

    if (this.rows.length) {
        var largeurFenetre = $(window).width();
        var isLg = false;
        var isMd = false;
        var isSm = false;
        var isXs = false;

        if (largeurFenetre > this.vpLarge) {
            this.addNoMargin(this.rows, this.classeLarge);
            isLg = true;
        }
        else {
            if (largeurFenetre > this.vpMedium) {
                this.addNoMargin(this.rows, this.classeMedium);
                isMd = true;
            }
            else {
                if (largeurFenetre > this.vpSmall) {
                    this.addNoMargin(this.rows, this.classeSmall);
                    isSm = true;
                }
                else{
                    if (largeurFenetre > this.vpXSmall) {
                        this.addNoMargin(this.rows, this.classeXSmall);
                        isXs = true;
                    }
                }
            }
        }


        var that = this;
        $(window).on('resize', function () {
            largeurFenetre = $(this).width();

            if (largeurFenetre > that.vpLarge) {
                if (!isLg) {
                    isLg = true;
                    isMd = false;
                    isSm = false;
                    isXs = false;
                    that.removeNoMargin(that.rows, that.classeMedium);
                    that.removeNoMargin(that.rows, that.classeSmall);
                    that.removeNoMargin(that.rows, that.classeXSmall);
                    that.addNoMargin(that.rows, that.classeLarge);
                }
            }

            if (largeurFenetre <= that.vpLarge) {
                if (largeurFenetre > that.vpMedium) {
                    if (!isMd) {
                        isLg = false;
                        isMd = true;
                        isSm = false;
                        isXs = false;
                        that.removeNoMargin(that.rows, that.classeLarge);
                        that.removeNoMargin(that.rows, that.classeSmall);
                        that.removeNoMargin(that.rows, that.classeXSmall);
                        that.addNoMargin(that.rows, that.classeMedium);
                    }
                }
            }

            if (largeurFenetre <= that.vpMedium) {
                if (largeurFenetre > that.vpSmall) {
                    if (!isSm) {
                        isLg = false;
                        isMd = false;
                        isSm = true;
                        isXs = false;
                        that.removeNoMargin(that.rows, that.classeLarge);
                        that.removeNoMargin(that.rows, that.classeMedium);
                        that.removeNoMargin(that.rows, that.classeXSmall);
                        that.addNoMargin(that.rows, that.classeSmall);
                    }
                }
            }

            if (largeurFenetre <= that.vpSmall) {
                if (largeurFenetre > that.vpXSmall) {
                    if (!isXs) {
                        isLg = false;
                        isMd = false;
                        isSm = false;
                        isXs = true;
                        that.removeNoMargin(that.rows, that.classeLarge);
                        that.removeNoMargin(that.rows, that.classeMedium);
                        that.removeNoMargin(that.rows, that.classeSmall);
                        that.addNoMargin(that.rows, that.classeXSmall);
                    }
                }
            }
        });
    }
};
