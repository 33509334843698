
//******************************************//
///////////////// SLIDERS ////////////////////
//******************************************//

/**
 * @constructor
 * @type {Sliders}
 */
module.exports = Sliders = function(config){
    if( config.sliders )
        this.sliders = $(config.sliders);
    else
        this.sliders =  $('[id*="id-slider-"]');
};


/**
 * Création de plusieurs instances de slick en fonction du nombre de sliders trouvés dans la page
 */
Sliders.prototype.init = function(){
    this.sliders.each(function () {
        // Id du slider en cours de lecture
        var currentId = $(this).attr('id');
        var currentSlider = $('#' + currentId);
        var contCurrentSlider = currentSlider.find('.slider__cont');

        // Récupération du nb de slide AVANT L'INITIALISATION DE SLICK (c'est trés important)
        // Si on le place après, slick aura fait tout ses traitements et aura géréné plusieurs
        // autres slides temporaires pour qu'il fonctionne correctement, faussant donc le résultat
        var nbSlide = currentSlider.find('.slider__slide').length;

        // Récupération de tous les paramètres pour le slider courant
        var params = currentSlider.data('params-slider');

        contCurrentSlider.slick({
            adaptiveHeight: params.adaptiveHeight, // false
            arrows: params.arrows, // true
            asNavFor: params.asNavFor, // null
            autoplay: params.autoplay, // false
            autoplaySpeed: params.autoplaySpeed, // 3000
            appendDots: currentSlider.find('.slider__pagination'),
            centerMode: params.centerMode, // false
            centerPadding: params.centerPadding, // '50px'
            cssEase: params.cssEase, // 'ease'
            dots: params.dots, // false
            draggable: params.draggable, // true
            edgeFriction: params.edgeFriction, // 0.15 ==> pris en compte uniquement quand slider est non infini
            easing: params.easing, // 'linear'
            fade: params.fade, // false
            focusOnSelect: params.focusOnSelect, // false
            infinite: params.infinite, // true
            initialSlide: params.initialSlide, // 0
            lazyLoad: params.lazyLoad, // défaut --> 'ondemand' /////// 'progressive'
            prevArrow: currentSlider.find('.slick-prev'), //
            nextArrow: currentSlider.find('.slick-next'), //
            pauseOnFocus: params.pauseOnFocus, // true
            pauseOnHover: params.pauseOnHover, // true
            pauseOnDotsHover: params.pauseOnDotsHover, // false
            respondTo: params.respondTo, // défaut --> 'window' ////// 'slider' 'min'
            responsive: params.responsive, // none
            rows: params.rows, // 1
            slide: params.slide, // ''
            slidesPerRow: params.slidesPerRow, // 1
            slidesToShow: params.slidesToShow, // 1
            slidesToScroll: params.slidesToScroll, // 1
            speed: params.speed, // 300ms
            touchThreshold: params.touchThreshold, // 5
            touchMove: params.touchMove, // true
            useCSS: params.useCSS, // true
            useTransform: params.useTransform, // true
            variableWidth: params.variableWidth, // false
            vertical: params.vertical, // false
            waitForAnimate: params.waitForAnimate, // true
            zIndex: params.zIndex // 1000
        });

        // On cache les flèches
        if (params.arrows === false) {
            currentSlider.find('.slick-prev').css('display', 'none');
            currentSlider.find('.slick-next').css('display', 'none');
        }

        // Configuration de la pagination custom
        currentSlider.find('.slider__custom-paging-total').html(nbSlide);
        contCurrentSlider.on('afterChange', function () {
            var currentSlide = $(this).slick('slickCurrentSlide') + 1;
            currentSlider.find('.slider__custom-paging-current').html(currentSlide);
        });

    });
};
