
//*********************************************//
///////////////// FORMULAIRE ////////////////////
//*********************************************//

/**
 * @constructor
 * @type {Form}
 */
module.exports = Form = function(){
    this.allFormDefaut = $('.form-defaut');

    this.fields = $('.form-defaut input[type="text"],.form-defaut input[type="password"],.form-defaut input[type="tel"],.form-defaut input[type="email"],.form-defaut textarea,.form-defaut select');
    this.fieldsFile = $('.form-defaut input[type="file"]');

    this.fieldsLabelInside = $('.form-defaut .label-main--inside');

    this.exceptions = ['iban','secu','cb'];
};


/**
 * Ajout de la classe is-actif-field en fonction de l'événement sur les éléments de formulaire
 */
Form.prototype.eventForm = function(){
    this.fields.on({
        'focus': function () {
            $(this).parents('.form-defaut__cont-field').addClass('is-actif-field');
        },
        'click': function () {
            $(this).parents('.form-defaut__cont-field').addClass('is-actif-field');
        },
        'blur': function () {
            // On vérifie que le champs est vide
            // Si celui-ci n'est pas vide, on laisse la classe .is-actif-field
            if( !$(this).val() ){
                $(this).parents('.form-defaut__cont-field').removeClass('is-actif-field');
            }
        }
    });
};


/**
 * Suppression des --- généré sur le premier option des selects
 */
Form.prototype.selectWP = function(){
    var options = this.fields.find('option');
    if( options.length ){
        var regex = new RegExp('---','gi');
        options.each(function(){
            if( $(this).text().match(regex) ){
                $(this).text('');
            }
        });
    }
};


/**
 * Suppression du label pour les options vide (présentant la data option-empty)
 */
Form.prototype.deleteOptionEmpty = function () {
    var emptyOptions = $('option[data-option-empty]');
    if (emptyOptions.length) {
        emptyOptions.each(function(){
            $(this).text('');
        });
    }
};


/**
 *
 */
Form.prototype.changementValeurChamps = function(current){
    var currentId = current.attr('id');

    // Si le l'id du champs de fait pas parti de la liste d'exceptions
    if( this.exceptions.indexOf(currentId) <= -1 ){
        if( current.attr('type') === 'email' ){
            var currentVal = current.val();

            // Clean de l'email ==> UpperCase to LowerCase
            currentVal = currentVal.replace(/[A-Z]/g, function(match,index,stringComplete){
                return match.toLowerCase();
            });

            // Regex adresse mail
            var regex = new RegExp(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.[a-z]{2,9}|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/);

            // Vérification si le mail rentré est valide
            if( regex.test(current.val()) ){
                current.parents('.obligatoire').removeClass('is-error').addClass('is-ok');
            }
            else {
                current.parents('.obligatoire').removeClass('is-ok').addClass('is-error');
            }


            // Champs de confirmation
            if( current.attr('name') === 'confirm_email' ){
                var email = current.parents('form').find('[name="email"]');
                if( email ){
                    if( email.val() === current.val() && current.val() !== '' ){
                        current.parents('.obligatoire').removeClass('is-error').addClass('is-ok');
                    }
                    else{
                        current.parents('.obligatoire').removeClass('is-ok').addClass('is-error');
                    }
                }
            }


            // Au cas où l'utilisateur modifierai son adresse mail après avoir rempli le champs confirmation d'email
            if( current.attr('name') === "email" ){
                var confirmEmail = current.parents('form').find('[name="confirm_email"]');
                if( confirmEmail ){
                    if( confirmEmail.val() === current.val() && current.val() !== '' ){
                        confirmEmail.parents('.obligatoire').removeClass('is-error').addClass('is-ok');
                    }
                    else{
                        confirmEmail.parents('.obligatoire').removeClass('is-ok').addClass('is-error');
                    }
                }
            }

        }
        else {
            if (current.attr('type') === 'checkbox') {
                var parent = current.parents('.wpcf7-checkbox');
                var enfants = parent.find('input[type="checkbox"]');
                var obligatoire = current.parents('.form-defaut__cont-field--checkbox.obligatoire');

                // Si les checkbox sont obligatoires, on vérifie qu'au moins une checkbox est cochée
                if (obligatoire.length) {
                    var ok = false;
                    enfants.each(function(){
                        if ($(this).prop("checked")) {
                            ok = true;
                            return false;
                        }
                    });

                    if (ok) {
                        obligatoire.removeClass('is-error').addClass('is-ok');
                    }
                    else {
                        obligatoire.removeClass('is-ok').addClass('is-error');
                    }
                }
            }
            else{
                if( current.val() ){
                    current.parents('.obligatoire').removeClass('is-error').addClass('is-ok');
                }
                else{
                    current.parents('.obligatoire').removeClass('is-ok').addClass('is-error');
                }
            }

            // if (current.attr('id') === 'telephone') {
            //
            //     // On vérifie le nombre de chiffre rentré
            //     var nbNum = current.val().match(/\d/gi);
            //
            //     // S'il n'y a aucun chiffre
            //     if( nbNum == null ){
            //        // Suppression des caractères non désiré et réinjection dans le champs input
            //        var tmp = current.val().replace(/(\D)/gi, "");
            //        $(current).val(tmp);
            //     }
            //     else{
            //        if( nbNum.length <= 10 ){
            //            // On clean la valeur actuelle pour ne garder que les chiffres
            //            var cleanTelVar = current.val().replace(/(\D)/gi, "");
            //
            //            // Formatage du numéro avec des tirets
            //            var newTelVar = cleanTelVar.replace(/(\d\d)(?=\d)/gi, "$1-");
            //            $(current).val(newTelVar);
            //        }
            //        else{
            //            // Formatage du numéro avec des tirets
            //            var newTelVarTest = current.val().replace(/(\d{2}-\d{2}-\d{2}-\d{2}-\d{2})(?=\d)/gi, "$1  ");
            //            newTelVarTest = newTelVarTest.replace(/\s\s\d/,"");
            //            $(current).val(newTelVarTest);
            //
            //            current.parents('.obligatoire').removeClass('is-error').addClass('is-ok');
            //        }
            //     }
            // }
        }
    }

    // Vérification de tous les champs du formulaire courant
    // afin de disabled le bouton submit. C'est simplement une surcouche du traitement fait par
    // wordpress et par nos tests précédents
    this.validForm(current);
};


/**
 * Grâce à la librairie jquery.inputmask, il est possible de formater des valeurs en cours de saisie.
 * Todo: Il faudra implémenter à cette fonction la possibilité d'activer ou non un type de formatage particulier, en fonction d'un pays, .....
 * @private
 */
Form.prototype.formatageValue = function(){

    if (typeof Inputmask !== "undefined") {

        // Application d'un masque qui va formater la saisie de l'utilisateur
        Inputmask.extendDefaults({
            'autoUnmask': false,
            'showMaskOnHover': false,
            'showMaskOnFocus': true,
            onincomplete: function (e) {
                $(e.currentTarget).parents('.form-defaut__cont-field').removeClass('is-ok').addClass('is-error');
            },
            oncomplete: function (e) {
                $(e.currentTarget).parents('.form-defaut__cont-field').removeClass('is-error').addClass('is-ok');
            }
        });

        Inputmask.extendAliases({
            'tel': {
                'mask': "99-99-99-99-99"
            },
            'iban': {
                'mask': "aa99 **** **** **** **** ******",
                'casing': "upper"
            },
            'secu': {
                'mask': "9 99 99 99999 99"
            },
            'cb': {
                'mask': "99-99-99-99-99"
            },
            'date': {
                'mask': "99/99/9999"
            }
        });

        // Activation des masques pour les inputs (si data-inputmask est renseigné)
        $(document).ready(function(){
            $(":input").inputmask();
        });
    }
};


/**
 * On vérifie que tous les champs obligatoire ont bien été remplis
 * Si ce n'est pas le cas, on désactive le bouton submit
 * @param elem
 */
Form.prototype.validForm = function(elem){

    // Formulaire du champs
    var form = elem.parents('form');

    var activeSubmit = true;
    var field = form.find('.form-defaut__cont-field.obligatoire');
    field.each(function(){
        if( $(this).hasClass('is-error') || !$(this).hasClass('is-ok') ){
            activeSubmit = false;
            return false;
        }
    });

    // Bouton submit
    var submit = form.find('input[type="submit"]');
    // if( activeSubmit ){
    //     submit.removeAttr('disabled');
    // }
    // else{
    //     submit.attr('disabled','disabled');
    // }
};


/**
 * Evénements les champs de formulaire
 */
Form.prototype.eventFields = function(){
    var that = this;
    $('.form-defaut input[type="text"],.form-defaut input[type="password"],.form-defaut input[type="email"],.form-defaut input[type="radio"],.form-defaut input[type="checkbox"],.form-defaut textarea').on({
        'keyup': function () {
            that.changementValeurChamps($(this));
        },
        'blur': function () {
            that.changementValeurChamps($(this));
        },
        'change': function () {
            that.changementValeurChamps($(this));
        }
    });


    // $('input[type="text"][name="naissance"]').on('click', function () {
    //     $(this).dateDropper();
    // });


    $('.form-defaut input[type="file"]').on({
        'click': function () {
            that.changementValeurChamps($(this));
        },
        'change': function () {
            that.changementValeurChamps($(this));
        },
        'blur': function () {
            that.changementValeurChamps($(this));
        },
        'focus': function () {
            that.changementValeurChamps($(this));
        },
        'select': function () {
            that.changementValeurChamps($(this));
        }
    });

    $('.form-defaut select').on({
        'change': function () {
            that.changementValeurChamps($(this));
        },
        'blur': function () {
            that.changementValeurChamps($(this));
        }
    });
};


/**
 * Pour le champs file
 * @param input
 */
Form.prototype.majFile = function(input){
    var fileReturn = input.parents('.input-file').find('.input-file__return');
    fileReturn.text(input.val());
    var parent = input.parents('.form-defaut__cont-field');
    if (input.val() !== "") {
        parent.addClass('is-actif-field');
    }
    else {
        if (parent.hasClass('is-actif-field')) {
            parent.removeClass('is-actif-field');
        }
    }
};

Form.prototype.eventFile = function(){
    var that = this;
    if( this.fieldsFile.length ){
        this.fieldsFile.each(function(){
            $(this).on('change', function () {
                that.majFile($(this));
            });
        });
    }
};


/**
 * Si lorsque l'on arrive sur la page des champs avec la classe .label-main--inside ont été rempli
 */
Form.prototype.checkFieldLabelInside = function () {
    if (this.fieldsLabelInside.length) {
        this.fieldsLabelInside.each(function(){
            var parent = $(this).parents('.form-defaut__cont-field');
            var input = parent.find('input');
            var textarea = parent.find('textarea');
            var select = parent.find('select');

            if ((select.length && select.val()) || (textarea.length && textarea.val()) || (input.length && input.val()) ) {
                parent.addClass('is-actif-field');

                if (select.val()) {
                    select.trigger('change');
                }
                else {
                    if (textarea.val()) {
                        select.trigger('change');
                    }
                    else {
                        if (input.val()) {
                            input.trigger('change');
                        }
                    }
                }
            }
        });
    }
};


/**
 * Initialisation
 */
Form.prototype.init = function(){
    this.selectWP();
    this.deleteOptionEmpty();
    this.eventForm();
    this.eventFile();
    this.formatageValue();
    this.checkFieldLabelInside();

    // this.validForm(this.allFormDefaut);

    var wpcf7Elm = document.querySelector( '.wpcf7' );
    if( wpcf7Elm !== null ){
        wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            $('#' + event.detail.id).find('.form-defaut .form-defaut__cont-field').each(function(){
                $(this).removeClass('is-ok').removeClass('is-error').removeClass('is-actif-field');
            });
        }, false );
    }

    this.eventFields();
};

